import "./EditHcpRateOfAgency.scss";
import {useSelector} from "react-redux";
import React, {useCallback, useEffect} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {IRootReducerState} from "../../../../../store/reducers";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import LoaderComponent from "../../../../../shared/components/loader/LoaderComponent";
import SwitchComponent from "../../../../../shared/components/form-controls/switch/SwitchComponent";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import HorizontalLineComponent
    from "../../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc, Patterns} from "../../../../../constants";

interface AgencyHCPRatesProps {
    selectedAgencyDetails: any;
    agencyId: any;
    closeAgencyHcpRateEditDrawer: () => void;
    getAgencyDetails: () => void;
    selectedFacility: any;
}

const EditHcpRateOfAgencyComponent = (props: AgencyHCPRatesProps) => {
    const {
        isHcpTypesListLoaded,
        isHcpTypesListLoading,
        HcpTypesList,
    } = useSelector((state: IRootReducerState) => state.meta);
    const {
        selectedAgencyDetails,
        agencyId,
        closeAgencyHcpRateEditDrawer,
        getAgencyDetails,
        selectedFacility
    } = props;

    const mergedTabList = useCallback(() => {
        return HcpTypesList.map((type: any) => {
            const matchingRate = selectedAgencyDetails?.agency_details?.hcp_type_rates?.find((rate: any) => rate.hcp_type_id === type.code);
            return {
                ...type,
                enabled: !!matchingRate,
                value: matchingRate ? matchingRate.rate : 0
            };
        });
    }, [HcpTypesList, selectedAgencyDetails]);


    useEffect(() => {
        mergedTabList();
    }, [mergedTabList]);


    const handleAgencyHcpRateEditSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const modifiedData = values?.rates?.filter((item: any) => item.enabled).map((item: any) => ({
            hcp_type_id: item.code,
            rate: Number(item.value),
            pay_rate_key:item.pay_rate_key,
        }));
        const payload: any = {};
        payload.hcp_type_rates = modifiedData;
        CommonService._agency.EditFacilityAgencyRates(selectedFacility, agencyId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getAgencyDetails();
                closeAgencyHcpRateEditDrawer();
            }).catch((error: any) => {
            CommonService._alert.showToast(error.error || error.message || 'Failed to update agency rates','error')
        }).finally(() => {
            setSubmitting(false);
            closeAgencyHcpRateEditDrawer();
        });
    }, [selectedFacility, agencyId, getAgencyDetails, closeAgencyHcpRateEditDrawer]);

    return (<>
            <div className={'edit-agency-hcp-rate-screen'}>
                <div className={'edit-agency-hcp-rate-header'}>
                    <div className={'form-header-text'}>Edit Agency Staff Rates</div>
                    <div className={'icon-button'} onClick={closeAgencyHcpRateEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                <div className={"agency-hcp-rate-component"}>
                    <div className={'rate-form-container'}>
                        <div className={'rate-form'}>
                            <Formik
                                initialValues={{rates: mergedTabList()}}
                                onSubmit={handleAgencyHcpRateEditSubmit}
                            >
                                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form className={'rate-normal-from'}>
                                            {isHcpTypesListLoading && <LoaderComponent/>}
                                            <div>
                                                <div className={"agency-hcp-rate-wrapper"}>
                                                    {isHcpTypesListLoaded &&
                                                        <>
                                                            {
                                                                values.rates.map((rate, index) => (
                                                                    <div key={index} className={'rate-list-item'}>
                                                                        <div className={'center-right-element'}>
                                                                            <label
                                                                                style={{
                                                                                    minWidth: "250px",
                                                                                    maxWidth: "250px"
                                                                                }}>{rate.title}</label>
                                                                            <SwitchComponent
                                                                                label={''}
                                                                                checked={rate.enabled}
                                                                                onChange={(checked) => {
                                                                                    setFieldValue(`rates[${index}].enabled`, checked)
                                                                                    if (!checked) {
                                                                                        setFieldValue(`rates[${index}].value`, "");
                                                                                }
                                                                                }}
                                                                            />
                                                                            {rate.enabled && (
                                                                                <div className={'rates-input-wrapper'}>
                                                                                    <Field
                                                                                        type="number"
                                                                                        name={`rates[${index}].value`}
                                                                                    >
                                                                                        {
                                                                                            (field: FieldProps) => (
                                                                                                <FormikInputComponent
                                                                                                    className={"mrg-bottom-set-zero"}
                                                                                                    label={''}
                                                                                                    placeholder={'Rates'}
                                                                                                    validationPattern={Patterns.NATURAL_INTERGER_DECIMALS}
                                                                                                    type={"number"}
                                                                                                    required={rate.enabled}
                                                                                                    formikField={field}
                                                                                                    fullWidth={true}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </Field>
                                                                                </div>)}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <HorizontalLineComponent/>
                                            <div className={'button-wrapper center-element'}>
                                                <ButtonComponent
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    className={"mrg-top-5"}
                                                    color={"download-button"}
                                                >
                                                    Save Details
                                                </ButtonComponent>
                                            </div>
                                        </Form>);
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditHcpRateOfAgencyComponent;