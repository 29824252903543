import "./UserManagementComponent.scss";
import React, {useCallback, useState} from "react";
// import {ITableColumn} from "../../../shared/models/table.model";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {CommonService} from "../../../shared/services";
// import TagComponent from "../../../shared/components/tag/TagComponent";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import {useSelector} from "react-redux";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
// import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditUserComponent from "../edit-user/EditUserComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import {IRootReducerState} from "../../../store/reducers";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../shared/components/antd-table-component/AntTableComponent";

const UserManagementListComponent = () => {
    const [activeTab, setActiveTab] = useState<any>("facility-user");
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const {currentUser}=useSelector((state:IRootReducerState)=>state.account);
    const [agencyFilterData, setAgencyFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const [isEditUserDetailsDrawerOpen, setIsEditUserDetailsDrawerOpen] = useState<any>(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isAgencyDownloading, setIsAgencyDownloading] = useState<boolean>(false);

    const handleDownloadUsers = useCallback(() => {
        setIsDownloading(true);
        let apiCall: any = {};
        apiCall = CommonService._user_management.downloadUserDataCSVAPICall(filterData);
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);


    const handleAgencyDownloadUsers = useCallback(() => {
        setIsAgencyDownloading(true);
        let apiCall: any = {};
        apiCall = CommonService._user_management.downloadAgencyUserDataCSVAPICall(agencyFilterData);
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsAgencyDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsAgencyDownloading(false);
            }
        })
            .catch((error: any) => {
                setIsAgencyDownloading(false);
            }).finally(() => {

        });
    }, [agencyFilterData]);


    const onUserActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  User ?`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the selected
    User?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivateDeactivateFacilityAdmin(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);

    const onAgencyUserActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  User?`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the selected
    User?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyAdmin(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setAgencyFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);

    const UserManagementListColumn:TableColumnsType=[
        {
            title:"User Name",
            width: 250,
            sorter: (a: any, b: any) =>
                (a.first_name + " " + a.last_name).localeCompare(b.first_name + " " + b.last_name),
            render: (item: any) => {
                return <div className={'user-management-details-wrapper'}>
                    {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                    <div className={'user-management-name-wrapper'}>
                        {
                            (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={item?.first_name + " " + item?.last_name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {item?.first_name + " " + item?.last_name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="facility-name">
                                    {item?.first_name + " " + item?.last_name || 'N/A'}
                                </div>
                        }
                    </div>
                </div>;
            }
        },
        {
            title:"Facility Name",
            key: 'facility_details',
            dataIndex: 'facility_details',
            // sortable: true,
            width: 250,
            sorter: (a: any, b: any) =>
                a?.facility_details?.name?.localeCompare(b?.facility_details?.name || '') || 0,
            render: (item: any) => {
                return <div className={'facility-details-wrapper'}>
                    <div className={'facility-name-tower-wrapper'}>
                        {
                            (item?.name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={item?.name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {item?.name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="facility-name">
                                    {item?.name || 'N/A'}
                                </div>
                        }
                    </div>
                </div>;
            }
        },
        {
            title:"Email ID",
            key: 'email',
            dataIndex: 'email',
            // sortable: true,
            width: 250,
            sorter: (a: any, b: any) => a.email?.localeCompare(b.email || '') || 0,
            render: (email: any) => {
                return <div className={'hcp-details-wrapper'}>
                    {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                    <div className={'hcp-name-tower-wrapper'}>
                        {
                            email?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={email}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div className={"ellipses-for-table-data property-name"}>
                                        {email}
                                    </div>
                                </ToolTipComponent> :
                                <div className="hcp-name">
                                    {email || 'N/A'}
                                </div>
                        }
                    </div>
                </div>;
            }
        },
        {
            title:'Role',
            key:'role',
            dataIndex:'role',
            width:100,
            sorter: (a: any, b: any) => a.role?.localeCompare(b.role || ''),
            render:(item:any)=>{
                return (
                    <div>
                        {item?.length > 10 ?
                            <ToolTipComponent
                                tooltip={item}
                                showArrow={true}
                                position={"top"}
                            >
                                <div className={"ellipses-for-table-data property-name"}>
                                    {item}
                                </div>
                            </ToolTipComponent>:
                            <div className="hcp-name">
                                {item || 'N/A'}
                            </div>
                        }
                    </div>
                )
            }
        },
        {
            title:"Phone Number",
            key: 'contact_number',
            dataIndex: 'contact_number',
            width: 150,
            // sorter: (a: any, b: any) =>
            //     a.contact_number?.localeCompare(b.contact_number || '') || 0,
            render: (contactNo: any) => {
                return <>
                    {CommonService.formatPhoneNumber(contactNo?.replace(/^\+1/, '')) || 'N/A'}
                </>;
            }
        },
        // {
        //     title:"Notification Preference",
        //     key: 'notification_preferences',
        //     dataIndex: 'notification_preferences',
        //     align: 'left',
        //     width: 200,
        //     render: (item: any) => {
        //         return <>
        //             <div className={'d-flex'}>
        //                   <span>
        //                       {
        //                           item?.isEmailRequired || item?.isPush_notificationRequired || item?.isSmsRequired
        //                               ? [
        //                                   item?.isEmailRequired && "Email",
        //                                   item?.isPush_notificationRequired && "In App",
        //                                   item?.isSmsRequired && "SMS"
        //                               ]
        //                                   .filter(Boolean)
        //                                   .join(", ")
        //                               : "N/A"
        //                       }
        //                   </span>
        //             </div>
        //         </>;
        //     }
        // },
        {
            title: 'Active/Inactive',
            align: 'center',
            width: 140,
            render: (item: any) => {
                return <div className={'display-center'}>
                    <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                        onUserActivateDeactivate(item);
                    }}/>
                </div>;
            }
        },
        {
            title: "Edit Details",
            align: 'center',
            width: 130,
            render: (item: any) => {
                if (item?._id) {
                    return <LinkComponent onClick={() => {
                        setIsEditUserDetailsDrawerOpen(true);
                        setUserDetails(item);
                    }}>
                        <ImageConfig.PencilEditIcon/>
                    </LinkComponent>;
                }
            }
        }
    ]

    const AgencyUserManagementListColumn: TableColumnsType=
        [
            {
                title:"User Name",
                // sortable: true,
                width: 250,
                sorter: (a: any, b: any) =>
                    (a.first_name + " " + a.last_name).localeCompare(b.first_name + " " + b.last_name),
                render: (item: any) => {
                    return <div className={'user-management-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'user-management-name-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.first_name + " " + item?.last_name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Agency Name",
                key: 'agencyDetails',
                dataIndex: 'agencyDetails',
                // sortable: true,
                width: 250,
                sorter: (a: any, b: any) =>
                    a?.agencyDetails?.name?.localeCompare(b?.agencyDetails?.name || '') || 0,
                render: (agency: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (agency?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={agency?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {agency?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {agency?.name || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Email ID",
                key: 'email',
                dataIndex: 'email',
                // sortable: true,
                width: 250,
                sorter: (a: any, b: any) => a.email?.localeCompare(b.email || '') || 0,
                render: (email: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {email || 'N/A'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title:"Phone Number",
                key: 'contact_number',
                dataIndex: 'contact_number',
                width: 170,
                // sorter: (a: any, b: any) =>
                //     a.contact_number?.localeCompare(b.contact_number || '') || 0,
                render: (contactNo: any) => {
                    return <>
                        {CommonService.formatPhoneNumber(contactNo?.replace(/^\+1/, '')) || 'N/A'}
                    </>;
                }
            },
            // {
            //     title:"Notification Preference",
            //     key: 'notification_preferences',
            //     dataIndex: 'notification_preferences',
            //     align: 'left',
            //     width: 200,
            //     render: (item: any) => {
            //         return <>
            //             <div className={'d-flex'}>
            //               <span>
            //                   {
            //                       item?.isEmailRequired || item?.isPush_notificationRequired || item?.isSmsRequired
            //                           ? [
            //                               item?.isEmailRequired && "Email",
            //                               item?.isPush_notificationRequired && "In App",
            //                               item?.isSmsRequired && "SMS"
            //                           ]
            //                               .filter(Boolean)
            //                               .join(", ")
            //                           : "N/A"
            //                   }
            //              </span>
            //             </div>
            //         </>;
            //     }
            // },
            {
                title: 'Active/Inactive',
                align: 'center',
                width: 170,
                render: (item: any) => {
                    return <div className={'display-center'}>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onAgencyUserActivateDeactivate(item);
                        }}/>
                    </div>;
                }
            },
            {
                title: "Edit Details",
                align: 'center',
                width: 130,
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setIsEditUserDetailsDrawerOpen(true);
                            setUserDetails(item);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }
        ];


    const closeEditDrawer = useCallback(() => {
        setIsEditUserDetailsDrawerOpen(false);
    }, []);

    return (
        <>
            <DrawerComponent isOpen={isEditUserDetailsDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                <EditUserComponent filterData={activeTab === "facility-user" ? filterData : agencyFilterData}
                                   setFilterData={activeTab === "facility-user" ? setFilterData : setAgencyFilterData}
                                   userDetails={userDetails}
                                   closeEditDrawer={closeEditDrawer}
                                   selectedUserType={activeTab}
                />
            </DrawerComponent>
            <div className={'user-management-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.UserModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">User</div>
                        </div>
                    </div>
                    <div className="list-options">
                        {activeTab === "facility-user" &&
                            <div className={"mrg-top-20"}>
                                <SearchComponent
                                    size={'medium'}
                                    placeholder="Search Facility Name"
                                    value={filterData.search}
                                    onSearchChange={(value) => {
                                        setFilterData({...filterData, search: value, page: 1});
                                    }}
                                />
                            </div>}
                        {activeTab === "agency-user" &&
                            <div className={"mrg-top-20"}>
                                <SearchComponent
                                    size={'medium'}
                                    placeholder="Search Agency Name"
                                    value={agencyFilterData.search}
                                    onSearchChange={(value) => {
                                        setAgencyFilterData({...agencyFilterData, search: value, page: 1});
                                    }}
                                />
                            </div>
                        }
                        {activeTab === "facility-user" &&
                            <div>
                                <ButtonComponent color={'download-button'} variant={"contained"}
                                                 isLoading={isDownloading}
                                                 onClick={handleDownloadUsers}>
                                    Download CSV
                                </ButtonComponent>
                            </div>}
                        {activeTab === "agency-user" &&
                            <div>
                                <ButtonComponent color={'download-button'} variant={"contained"}
                                                 isLoading={isAgencyDownloading}
                                                 onClick={handleAgencyDownloadUsers}>
                                    Download CSV
                                </ButtonComponent>
                            </div>
                        }
                        {currentUser?.role !== 'super_admin' &&
                            <LinkComponent route={CommonService._routeConfig.AddUserManagementRoute()}>
                                <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                    Add New User
                                </ButtonComponent>
                            </LinkComponent>
                        }
                    </div>
                </div>
                <TabsWrapperComponent>
                    <TabsComponent
                        value={activeTab}
                        allowScrollButtonsMobile={false}
                        onUpdate={(e: any, v: any) => {
                            setActiveTab(v);
                            setFilterData({...filterData, status: v, page: 1, search: ""});
                            setAgencyFilterData({...agencyFilterData, status: v, page: 1, search: ""});
                        }}
                    >
                        <TabComponent label="Facility User" value={"facility-user"}/>
                        <TabComponent label="Agency User" value={"agency-user"}/>
                    </TabsComponent>
                    <div className={'user-list-component mrg-top-20'}>
                        <div className="">
                            <TabContentComponent value={activeTab} selectedTab={"facility-user"}>
                                {/*<TableWrapperComponent url={APIConfig.USER_MANAGEMENT_LIST.URL}*/}
                                {/*                       method={APIConfig.USER_MANAGEMENT_LIST.METHOD}*/}
                                {/*                       isPaginated={true}*/}
                                {/*                       extraPayload={filterData}*/}
                                {/*                       noDataText={filterData.search ? 'Oops! It seems like there are no user available for the name you have searched.' : 'No Data'}*/}
                                {/*                       columns={UserManagementListColumn}*/}
                                {/*/>*/}
                                <AntTableComponent
                                    url={APIConfig.USER_MANAGEMENT_LIST.URL}
                                    method={APIConfig.USER_MANAGEMENT_LIST.METHOD}
                                    extraPayload={filterData}
                                    isRowSelection={false}
                                    columns={UserManagementListColumn}
                                />
                            </TabContentComponent>
                            <TabContentComponent value={activeTab} selectedTab={"agency-user"}>
                                {/*<TableWrapperComponent url={APIConfig.AGENCY_USER_MANAGEMENT_LIST.URL}*/}
                                {/*                       method={APIConfig.AGENCY_USER_MANAGEMENT_LIST.METHOD}*/}
                                {/*                       isPaginated={true}*/}
                                {/*                       extraPayload={agencyFilterData}*/}
                                {/*                       noDataText={agencyFilterData.search ? 'Oops! It seems like there are no user available for the name you have searched.' : 'No User Data Found.'}*/}
                                {/*                       columns={AgencyUserManagementListColumn}*/}
                                {/*/>*/}
                                <AntTableComponent
                                    url={APIConfig.AGENCY_USER_MANAGEMENT_LIST.URL}
                                    method={APIConfig.AGENCY_USER_MANAGEMENT_LIST.METHOD}
                                    extraPayload={agencyFilterData}
                                    isRowSelection={false}
                                    columns={AgencyUserManagementListColumn}
                                />
                            </TabContentComponent>
                        </div>
                    </div>
                </TabsWrapperComponent>
            </div>
        </>
    );
};
export default UserManagementListComponent;
